import config from '../conf/config'

export const googleMapsDefaultCenter = { lat: 51.22188, lng: 6.7756 }

export const googleMapsZoom = 14

export const googleMapsComponentOptions = {
  streetViewControl: false,
  minZoom: 3,
  maxZoom: 20,
}

export const googleMapsJsApiLoaderParams: any = {
  googleMapsApiKey: config.MAP_API_KEY,
  id: 'google-map-script',
  libraries: ['visualization'],
}
