import { useSelector } from 'react-redux'
import { TState } from '../../../../../redux/config/root-types'
import { useMemo } from 'react'
import DateConverter from '../../../../../utils/helpers/date'
import { IHeatmapReport } from '../../../../../redux/modules/report/TReducer'
import { useTranslation } from 'react-i18next'
import { I18nPage } from '../../../../../utils/i18n/i18nPage'
import { ITrailerHeatmapInfo } from './ITrailerHeatmapInfo'

export const useOrganizationTrailersInfo = () => {
  const { t } = useTranslation(I18nPage.trailerHeatmap)

  const currentOrganization = useSelector(({ currentOrganization }: TState) => currentOrganization.data)
  const heatmapReports = useSelector(({ report }: TState) => report.heatmapReports)

  const getTotalReportInfo = (heatmapReports?: IHeatmapReport[]) => {
    const initialValue = { totalRentTime: 0, totalDistance: 0, totalRents: 0 }
    if (!heatmapReports?.length) return initialValue
    return heatmapReports.reduce((accumulator, currentValue) => {
      return {
        totalRentTime: accumulator.totalRentTime + (currentValue?.totalRentTime || 0),
        totalDistance: accumulator.totalDistance + (currentValue?.totalDistance || 0),
        totalRents: accumulator.totalRents + (currentValue?.totalRents || 0),
      }
    }, initialValue)
  }

  const trailerInfo: ITrailerHeatmapInfo = useMemo(() => {
    const { totalDistance, totalRentTime, totalRents } = getTotalReportInfo(heatmapReports)

    return {
      totalDistance,
      totalRentTime: DateConverter.minutesToDDHHMM(totalRentTime),
      totalRents,
      trailerCount: heatmapReports?.length || 0,
      title: currentOrganization?.name || '',
    }
  }, [heatmapReports, currentOrganization])

  return { trailerInfo, t }
}
