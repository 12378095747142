import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, select } from 'redux-saga/effects'
import { Actions_Positions } from '../../../modules/positions/Actions'
import { sagaAction_Error } from '../../actions/SagaAction_Error'
import { TState, TStoreTemplate } from '../../../config/root-types'
import { Actions_report } from '../../../modules/report/Actions'
import { IHeatmapReport } from '../../../modules/report/TReducer'
import { TPosition, TPositions } from '../../../modules/positions/TReducer'
import { RequestMethods } from '../../../../utils/axios/methods'
import { Nullable } from '../../../../utils/types'
import DateConverter from '../../../../utils/helpers/date'
import { Actions_Trailers } from '../../../modules/trailers/Actions'

const { get } = RequestMethods()

export interface ISagaWorker_GetHeatmapData {
  isSingleTrailer: boolean
  filters: {
    fromDate?: Nullable<Date>
    toDate?: Nullable<Date>
  }
}

function getTrailersImei(heatmapReports?: IHeatmapReport[]) {
  if (!heatmapReports?.length) return null
  return heatmapReports.map((trailer) => trailer.imei)
}

export default function* SagaWorker_GetHeatmapData(action: PayloadAction<ISagaWorker_GetHeatmapData>) {
  const { isSingleTrailer, filters } = action.payload

  yield put(Actions_Trailers.store.showLoader())

  try {
    const parsedFilters = {
      fromDate: DateConverter.toUtcStartOfDay(filters.fromDate),
      toDate: DateConverter.toUtcEndOfDay(filters.toDate),
    }

    const getCurrentTrailerId = function* () {
      const getCurrentTrailer = (state: TState) => state.currentTrailer.data
      const currentTrailer: ReturnType<typeof getCurrentTrailer> = yield select(getCurrentTrailer)
      return !!currentTrailer?.trailer?.id ? [currentTrailer.trailer.id] : null
    }

    const getOrganizationTrailersIds = function* () {
      const getOrganizationTrailers = (state: TState) => state.trailers.data
      const trailers: ReturnType<typeof getOrganizationTrailers> = yield select(getOrganizationTrailers)
      return !!trailers?.length ? trailers?.map((trailer) => trailer.trailer.id) : null
    }

    const trailerId: Nullable<number[]> = isSingleTrailer
      ? yield getCurrentTrailerId()
      : yield getOrganizationTrailersIds()

    //------------------------- Getting and saving heatmap report ---------------------------//
    const heatmapReports: TStoreTemplate<IHeatmapReport[]> = yield call(
      async () =>
        await get<TStoreTemplate<IHeatmapReport[]>>('/trailers/report/heatmap', { trailerId, ...parsedFilters }),
    )

    yield put(Actions_report.store.saveHeatmapReports(heatmapReports.data || []))

    //------------------------- Getting and saving positions ----------------------//
    const getHeatmapReports = (state: TState) => state.report.heatmapReports
    const trailersHeatmapReport: ReturnType<typeof getHeatmapReports> = yield select(getHeatmapReports)

    const imei = getTrailersImei(trailersHeatmapReport)

    if (!!imei?.length) {
      const response: TStoreTemplate<TPositions> = yield call(
        async () => await get<TPositions>('/positions', { imei, ...parsedFilters }),
      )

      const positions = response.data?.positions || []
      const responseInfo = response.responseInfo

      yield put(Actions_Positions.store.savePositions({ data: positions, responseInfo }))
    }
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Trailers.store.hideLoader())
  }
}
