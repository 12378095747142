import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Bookings } from './TActions'
import { TBookingFilters, TFutureBooking } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'
import { ISagaWorker_GetBookingInvoice } from 'redux/sagas/events/bookings/SagaWorker_GetBookingInvoice'
import { TTableFilterRedux } from 'utils/helpers/table'

const { StoreActions, SagaEvents } = ActionTypes_Bookings

export const Actions_Bookings = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    getBookingInvoice: createAction<ISagaWorker_GetBookingInvoice>(SagaEvents.GET_BOOKING_INVOICE),
    getBookings: createAction(SagaEvents.GET_BOOKINGS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveBookings: createAction<TStoreTemplateWithFilter<TFutureBooking[]>>(StoreActions.SAVE_BOOKINGS),
    changeFilter: createAction<TBookingFilters>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TBookingFilters>(StoreActions.INCREASE_FILTER_OFFSET),
    reset: createAction(StoreActions.RESET),
  },
}
