import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_report } from 'redux/modules/report/Actions'
import DateConverter from 'utils/helpers/date'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import type { PayloadAction } from '@reduxjs/toolkit'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TBookingFilters, TFutureBooking } from 'redux/modules/bookings/TReducer'
import { Actions_Bookings } from 'redux/modules/bookings/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_GetBookings({ payload }: PayloadAction<TBookingFilters>) {
  yield put(Actions_report.store.showLoader())

  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.bookings.filters
  const getBookings = (state: TState) => state.bookings.data

  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const oldData: ReturnType<typeof getBookings> = yield select(getBookings)

  const isNeedToConcatData = !!payload

  const formData = {
    organization,
    ...filter,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    searchStr: filter.searchStr ? filter.searchStr : undefined,
  }

  try {
    const result: TStoreTemplate<TFutureBooking[]> = yield call(
      async () => await get<TStoreTemplate<TFutureBooking[]>>('/bookings/forFuture', formData),
    )
    const responseInfo = result.responseInfo

    const data = isNeedToConcatData
      ? TableFilterReduxAdapter.concatData<TFutureBooking>(oldData, result.data)
      : result.data

    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(result.data, filter)

    yield put(Actions_Bookings.store.saveBookings({ data, responseInfo, isAllDataLoaded }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_report.store.hideLoader())
}
