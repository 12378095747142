export enum I18nModal {
  common = 'translation',
  stationCreate = 'stationCreate',
  stationUpdate = 'stationUpdate',
  parkingCreate = 'parkingCreate',
  trailerCreate = 'trailerCreate',
  trailerDelete = 'trailerDelete',
  trailerDeleteFromTrailersList = 'trailerDeleteFromTrailersList',
  trailerTypeActivation = 'trailerTypeActivation',
  trailerTypeDelete = 'trailerTypeDelete',
  organizationCreate = 'organizationCreate',
  organizationActivation = 'organizationActivation',
  addParkingToOrganization = 'addParkingToOrganization',
  addTrailerToOrganization = 'addTrailerToOrganization',
  organizationInvitationCodesCreate = 'organizationInvitationCodesCreate',
  removeParkingFromOrganizationConfirm = 'removeParkingFromOrganizationConfirm',
  removeTrailerFromOrganizationConfirm = 'removeTrailerFromOrganizationConfirm',
  parentOrganizationActivation = 'parentOrganizationActivation',
  parentOrganizationCreate = 'parentOrganizationCreate',
  parentOrganizationUpdate = 'parentOrganizationUpdate',
  staffCreate = 'staffCreate',
  staffActivation = 'staffActivation',
  staffChangeRole = 'staffChangeRole',
  staffDelete = 'staffDelete',
  removeStaffFromStaffTable = 'removeStaffFromStaffTable',
  exitConfirm = 'exitConfirm',
  resetPassword = 'resetPassword',
  trailerDetailsCommands = 'trailerDetailsCommands',
  userActivation = 'userActivation',
  userDelete = 'userDelete',
  userDiscountDelete = 'userDiscountDelete',
  UserToOrganizationDiscountCreate = 'UserToOrganizationDiscountCreate',
  userToOrganizationDiscountDelete = 'userToOrganizationDiscountDelete',
  resendBill = 'resendBill',
  deleteParkingConfirm = 'deleteParkingConfirm',
  completeRent = 'completeRent',
  verifyUser = 'verifyUser',
  removeUserFromUsersTable = 'removeUserFromUsersTable',
  lockStation = 'lockStation',
  promoCodeCreate = 'promoCodeCreate',
  promoCodeUpdate = 'promoCodeUpdate',
  promoCodeDelete = 'promoCodeDelete',
  breakdownHistory = 'breakdownHistory',
  organizationsFilter = 'organizationsFilter',
  statusFilter = 'statusFilter',
  trailerTypeFilter = 'trailerTypeFilter',
  viewTrailersTableColumns = 'viewTrailersTableColumns',
  rangeCalendar = 'rangeCalendar',
  trackerCodeDelete = 'trackerCodeDelete',
  trackerCodeCreate = 'trackerCodeCreate',
  bookingCancelFilter = 'bookingCancelFilter',
  bookingCompletedFilter = 'bookingCompletedFilter',
}
