import React, { FC } from 'react'
import { useTrailerHeatmap } from './TrailerHeatmap-ViewModel'
import { Shared } from 'ui/shared'
import Box from '@mui/material/Box'
import { GoogleMap, HeatmapLayerF } from '@react-google-maps/api'
import { TrailerHeatmapInfo } from './components/TrailerHeatmapInfo/TrailerHeatmapInfo'
import { TrailerHeatmapHeader } from './components/TrailerHeatmapHeader/TrailerHeatmapHeader'
import { googleMapsComponentOptions, googleMapsZoom } from '../../../utils/constants/googleMaps'

const INDENT = 24
const TITLE_HEIGHT = 50
const MAX_INTENSITY = 20

export const TrailerHeatmap: FC = () => {
  const vm = useTrailerHeatmap()
  const {
    t,
    classes,
    cx,
    closeModal,
    onLoad,
    onUnmount,
    isLoaded,
    defaultCenter,
    positions,
    onChangeDates,
    datePeriod,
    isLoading,
    isSingleTrailer,
  } = vm

  return (
    <Shared.Card
      style={{ padding: 0, width: `calc(100vw - ${INDENT * 2}px)`, height: `calc(100vh - ${INDENT * 2}px)` }}
    >
      <TrailerHeatmapHeader t={t} closeModal={closeModal} />
      <Box sx={{ height: `calc(100% - ${TITLE_HEIGHT}px)` }}>
        <div className={cx(classes.container, classes.dateRangeContainer)}>
          <Shared.RangeDatePicker
            name={'heatmap-date-range'}
            onSubmit={onChangeDates}
            startDate={datePeriod.fromDate}
            endDate={datePeriod.toDate}
            title={t('dateRange')}
          />
        </div>
        <TrailerHeatmapInfo
          isSingleTrailer={isSingleTrailer}
          cardClassName={cx(classes.container, classes.trailerInfoContainer)}
        />
        <div className={classes.map}>
          {isLoaded && !isLoading && (
            <GoogleMap
              mapContainerStyle={{ widows: '100%', height: '100%', borderRadius: '0 0 16px 16px' }}
              zoom={googleMapsZoom}
              center={defaultCenter}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                ...googleMapsComponentOptions,
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.BOTTOM_LEFT,
                },
              }}
            >
              <HeatmapLayerF data={positions} options={{ maxIntensity: MAX_INTENSITY }} />
            </GoogleMap>
          )}
          {!isLoaded || isLoading ? <Shared.Preloader mask /> : null}
        </div>
      </Box>
    </Shared.Card>
  )
}
