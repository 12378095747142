import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  src: string
  type: string
}

const TrailerIcon: FC<IProps> = ({ src }) => {
  const { classes } = useStyles()
  return <img className={classes.root} src={src} alt={''} />
}

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    top: '-12px',
    right: '24px',
    width: '6vw',
    maxWidth: 100,
    maxHeight: 100,
    borderRadius: 128,
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '10vw',
      top: '-5px',
      right: '5px',
      maxWidth: 100,
      maxHeight: 100,
    },
    [theme.breakpoints.only('xs')]: {
      width: '20vw',
      top: '-5px',
      right: '5px',
      maxWidth: 100,
      maxHeight: 100,
    },
  },
}))

export default TrailerIcon
