import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Actions_Modals } from '../../../redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nPage } from '../../../utils/i18n/i18nPage'
import { useJsApiLoader } from '@react-google-maps/api'
import { TState } from '../../../redux/config/root-types'
import { makeStyles } from 'tss-react/mui'
import DateConverter from '../../../utils/helpers/date'
import { TPosition } from '../../../redux/modules/positions/TReducer'
import { Actions_Positions } from '../../../redux/modules/positions/Actions'
import { Actions_Trailers } from '../../../redux/modules/trailers/Actions'
import { Nullable } from '../../../utils/types'
import { googleMapsDefaultCenter, googleMapsJsApiLoaderParams } from '../../../utils/constants/googleMaps'
import ArrayConverter from '../../../utils/helpers/array'

type TModalProps = {
  isSingleTrailer: boolean
}

interface IDatePeriod {
  fromDate?: Nullable<Date>
  toDate?: Nullable<Date>
}

export const useTrailerHeatmap = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nPage.trailerHeatmap)

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>()

  const { classes, cx } = useStyles()

  const { isLoaded, loadError } = useJsApiLoader(googleMapsJsApiLoaderParams)

  const { isSingleTrailer } = useSelector(({ modals }: TState) => modals.data as TModalProps)

  const { positions, isLoading, trailers, heatmapReports, currentTrailer } = useSelector(
    ({ positions, trailers, currentTrailer, report }: TState) => ({
      positions: positions.data || [],
      trailers: trailers.data,
      isLoading: trailers.isLoading,
      heatmapReports: report.heatmapReports,
      currentTrailer: currentTrailer.data,
    }),
    shallowEqual,
  )

  const [datePeriod, setDatePeriod] = useState<IDatePeriod>({
    fromDate: DateConverter.addDays(new Date(), -30),
    toDate: new Date(),
  })

  const closeModal = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  useEffect(() => {
    dispatch(Actions_Trailers.saga.getHeatmapData({ isSingleTrailer, filters: datePeriod }))
    return () => {
      dispatch(Actions_Positions.store.reset())
    }
  }, [isSingleTrailer, datePeriod, dispatch])

  useEffect(() => {
    if (map && center) {
      map.panTo(center)
    }
  }, [map, center])

  const setCurrentTrailerCenter = () => {
    !!currentTrailer && setTrailerCenter(currentTrailer?.trailer?.latitude, currentTrailer?.trailer?.longitude)
  }

  const setOrganizationTrailersCenter = () => {
    const firstReport = ArrayConverter.getFirstElementOfArray(heatmapReports)
    if (!!firstReport) {
      const trailer = !!trailers?.length ? trailers.find((trailer) => trailer.trailer.id === firstReport.id) : null
      trailer && setTrailerCenter(trailer?.trailer?.latitude, trailer?.trailer?.longitude)
    }
  }

  const setTrailerCenter = useCallback((lat?: number, lng?: number) => {
    const canSetCenter = !!lat && !!lng
    canSetCenter && setCenter({ lat, lng })
  }, [])

  useEffect(() => {
    isSingleTrailer ? setCurrentTrailerCenter() : setOrganizationTrailersCenter()
  }, [currentTrailer, trailers, heatmapReports, isSingleTrailer])

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback((map: google.maps.Map) => {}, [])

  const onChangeDates = useCallback((startDate?: Nullable<Date>, endDate?: Nullable<Date>) => {
    setDatePeriod({ fromDate: startDate, toDate: endDate })
  }, [])

  return {
    t,
    classes,
    cx,
    closeModal,
    onLoad,
    onUnmount,
    isLoaded,
    defaultCenter: googleMapsDefaultCenter,
    positions: isLoaded ? toPositionData(positions) : [],
    datePeriod,
    onChangeDates,
    isLoading,
    isSingleTrailer,
  }
}

const toPositionData = (positions: TPosition[]): google.maps.LatLng[] => {
  return !!positions.length
    ? positions.map((position) => new google.maps.LatLng(position.latitude, position.longitude))
    : []
}

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'absolute',
    zIndex: 110,
    width: '50%',
    maxWidth: 450,
    borderRadius: 10,
  },
  dateRangeContainer: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  trailerInfoContainer: {
    top: 224,
    left: 24,
  },
  map: {
    height: '100%',
    flexGrow: 2,
    position: 'relative',
  },
}))
