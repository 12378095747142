import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Controls } from '../../../../../assets/images/Controls'
import Box from '@mui/material/Box'
import { TFunction } from 'i18next'

interface ITrailerHeatmapHeaderProps {
  t: TFunction
  closeModal: () => void
}

export const TrailerHeatmapHeader: FC<ITrailerHeatmapHeaderProps> = ({ t, closeModal }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={(theme) => ({ p: theme.spacing(2, 2, 1) })}
    >
      <Typography sx={{ fontSize: '1.25rem', lineHeight: '1.125rem', fontWeight: 700, color: '#3C3B3B' }} noWrap>
        {t('title')}
      </Typography>
      <IconButton size={'small'} onClick={closeModal}>
        <Controls.CloseDarkSmall />
      </IconButton>
    </Box>
  )
}
