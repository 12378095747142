import { ButteryIcon } from 'ui/shared/BatteryIcon/ButteryIcon'
import { Dropdown } from 'ui/shared/Dropdown/Dropdown'
import { CardTitle } from 'ui/shared/Card/CardTitle/CardTitle'
import { Card } from 'ui/shared/Card/Card'
import { InputField } from 'ui/shared/InputField/InputField'
import { Divider } from 'ui/shared/Divider/Divider'
import { Preloader } from 'ui/shared/Preloader/Preloader'
import { Button } from 'ui/shared/Button/Button'
import { Page } from 'ui/shared/Page/Page'
import { Table } from 'ui/shared/Table/Table'
import { TableRow } from 'ui/shared/TableRow/TableRow'
import { TableCell } from 'ui/shared/TableCell/TableCell'
import { TableSimpleCell } from 'ui/shared/TableSimpleCell/TableSimpleCell'
import { TableIconCell } from 'ui/shared/TableIconCell/TableIconCell'
import { TableLinkCell } from 'ui/shared/TableLinkCell/TableLinkCell'
import { TableBatteryCell } from 'ui/shared/TableBatteryCell/TableBatteryCell'
import { TableBatteryChargeCell } from 'ui/shared/TableBatteryChargeCell/TableBatteryChargeCell'
import { TableCheckboxCell } from 'ui/shared/TableCheckboxCell/TableCheckboxCell'
import { TableStatusCell } from 'ui/shared/TableStatusCell/TableStatusCell'
import { TableHeaderCell } from 'ui/shared/TableHeaderCell/TableHeaderCell'
import { TableHeaderMenu } from 'ui/shared//TableHeaderMenu/TableHeaderMenu'
import Organization from 'ui/shared/Organization/Organization'
import InfoWindow from 'ui/shared/InfoWindow/InfoWindow'
import TrailerTypeList from 'ui/shared/TrailerTypeList/TrailerTypeList'
import MultiOrganization from 'ui/shared/MultiOrganization/MultiOrganization'
import MultiParking from 'ui/shared/MultiParking/MultiParking'
import { IconButton } from 'ui/shared/IconButton/IconButton'
import Role from 'ui/shared/Role/Role'
import SingleDatePickerForForm from 'ui/shared/SingleDatePickerForForm/SingleDatePickerForForm'
import RangeDatePicker from 'ui/shared/RangeDatePicker/RangeDatePicker'
import AutocompleteForForm from 'ui/shared/AutocompleteForForm/AutocompleteForForm'
import { Notes } from 'ui/shared/Notes/Notes'
import TrailerIcon from 'ui/shared/TrailerIcon/TrailerIcon'

export const Shared = {
  Dropdown,
  Button,
  Preloader,
  InputField,
  Card,
  CardTitle,
  Divider,
  ButteryIcon,
  Table,
  TableRow,
  TableCell,
  TableSimpleCell,
  TableIconCell,
  TableLinkCell,
  TableBatteryCell,
  TableBatteryChargeCell,
  TableCheckboxCell,
  TableStatusCell,
  TableHeaderMenu,
  Page,
  Organization,
  MultiOrganization,
  MultiParking,
  TableHeaderCell,
  InfoWindow,
  TrailerTypeList,
  IconButton,
  Role,
  SingleDatePickerForForm,
  RangeDatePicker,
  AutocompleteForForm,
  Notes,
  TrailerIcon,
}
