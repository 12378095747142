import { useSelector } from 'react-redux'
import { TState } from '../../../../../redux/config/root-types'
import { useMemo } from 'react'
import DateConverter from '../../../../../utils/helpers/date'
import { useTranslation } from 'react-i18next'
import { I18nPage } from '../../../../../utils/i18n/i18nPage'
import { ITrailerHeatmapInfo } from './ITrailerHeatmapInfo'

export const useCurrentTrailerInfo = () => {
  const { t } = useTranslation(I18nPage.trailerHeatmap)

  const { currentTrailer, trailerTypes, cdnUrl } = useSelector(
    ({ currentTrailer, trailerTypes, settings }: TState) => ({
      currentTrailer: currentTrailer.data?.trailer,
      trailerTypes: trailerTypes.data || [],
      cdnUrl: settings.data?.cdnUrl || '',
    }),
  )

  const { heatmapReports } = useSelector(({ report }: TState) => ({
    heatmapReports: report.heatmapReports,
  }))

  const getTrailerType = () => {
    const trailerTypeId = currentTrailer?.trailerTypeId
    if (!trailerTypeId) return null
    const currentTrailerType = trailerTypes.find(({ type: { id } }) => id === trailerTypeId)
    return currentTrailerType?.type
  }

  const trailerInfo: ITrailerHeatmapInfo = useMemo(() => {
    const trailerType = getTrailerType()
    const heatmapReport = heatmapReports?.find((report) => report.id === currentTrailer?.id)

    return {
      model: trailerType?.model || '',
      trailerType: trailerType?.name || '',
      trailerNumber: currentTrailer?.plate || '',
      totalDistance: heatmapReport?.totalDistance || 0,
      totalRentTime: heatmapReport?.totalRentTime ? DateConverter.minutesToDDHHMM(heatmapReport.totalRentTime) : '0',
      image: trailerType?.image ? `${cdnUrl}${trailerType.image}` : '',
      totalRents: heatmapReport?.totalRents || 0,
      title: trailerType?.name || '',
    }
  }, [currentTrailer, heatmapReports, cdnUrl])

  return { trailerInfo, t }
}
