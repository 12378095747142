import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Shared } from '../../../../shared'
import Typography from '@mui/material/Typography'
import { useCurrentTrailerInfo } from './CurrentTrailerInfo-ViewModel'
import { useOrganizationTrailersInfo } from './OrganizationTrailersInfo-ViewModel'
import CommonUtils from '../../../../../utils/helpers/common'

interface ITrailerHeatmapInfoProps {
  isSingleTrailer: boolean
  cardClassName: string
}

export const TrailerHeatmapInfo: FC<ITrailerHeatmapInfoProps> = ({
  isSingleTrailer,
  cardClassName,
}: ITrailerHeatmapInfoProps) => {
  const vm = isSingleTrailer ? useCurrentTrailerInfo() : useOrganizationTrailersInfo()
  const { t, trailerInfo } = vm

  return (
    <Shared.Card className={cardClassName}>
      {trailerInfo.image && trailerInfo.trailerType && (
        <Shared.TrailerIcon src={trailerInfo.image} type={trailerInfo.trailerType} />
      )}
      <Box mb={3} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} style={{ height: '100%' }}>
        <Shared.CardTitle title={trailerInfo.title} />
        {trailerInfo.model && (
          <Typography variant={'body2'} component={'p'}>
            <strong>{t('model')}: </strong>
            {trailerInfo.model}
          </Typography>
        )}
        {trailerInfo.trailerNumber && (
          <Typography variant={'body2'} component={'p'}>
            <strong>{t('trailerNumber')}: </strong>
            {trailerInfo.trailerNumber}
          </Typography>
        )}
        {!CommonUtils.isNil(trailerInfo.trailerCount) && (
          <Typography variant={'body2'} component={'p'}>
            <strong>{t('trailerCount')}: </strong>
            {trailerInfo.trailerCount}
          </Typography>
        )}
      </Box>
      <Box mb={3}>
        <Shared.CardTitle title={t('rentalFrequency')} />
        <Typography variant={'body2'} component={'p'}>
          {trailerInfo.totalRents}
        </Typography>
      </Box>
      <Box mb={3}>
        <Shared.CardTitle title={t('mileage')} />
        <Typography variant={'body2'} component={'p'}>
          {trailerInfo.totalDistance} m
        </Typography>
      </Box>
      <Box>
        <Shared.CardTitle title={t('totalRentTime')} />
        <Typography variant={'body2'} component={'p'}>
          {trailerInfo.totalRentTime}
        </Typography>
      </Box>
    </Shared.Card>
  )
}
